import { showSnackBar } from "~/shared_states/snackbar";

class AppWebView {
  static flowTrigger(name: string) {
    AppWebView._eventTrigger({
      triggerFlow: {
        name: name,
      },
    });
  }

  static externalLinkTrigger(link: string) {
    AppWebView._eventTrigger({
      openLinkExternal: {
        link: link,
      },
    });
  }

  static deeplinkTrigger(link: string) {
    AppWebView._eventTrigger({
      navigateDeeplink: {
        deeplink: link,
        noPush: false,
      },
    });
  }

  static _eventTrigger(event: Record<string, any>) {
    try {
      // @ts-ignore
      window.HubbleAppWebView.postMessage(
        JSON.stringify({
          type: "event",
          event: event,
        })
      );
    } catch (e: any) {
      showSnackBar({
        message: e.message,
        level: "error",
      });
    }
  }

  static fetchLocation() {
    AppWebView._actionTrigger("fetchLocation");
  }

  static goBack() {
    AppWebView._actionTrigger("goBack");
  }

  static fireProfileDirtyEvent() {
    AppWebView._actionTrigger("fireProfileDirtyEvent");
  }

  static getUser() {
    AppWebView._actionTrigger("getUser");
  }

  static _actionTrigger(action: string) {
    try {
      // @ts-ignore
      window.HubbleAppWebView.postMessage(
        JSON.stringify({
          type: "action",
          action: action,
        })
      );
    } catch (e: any) {
      showSnackBar({
        message: e.message,
        level: "error",
      });
    }
  }
}

export default AppWebView;
